<template>
  <div data-test-id="edit_drop_off_modal-wrapper">
    <ui-modal
      v-bind="$attrs"
      :size="SIZES.small"
      :open="isOpen"
      :header="$t('modal.edit_dropoff_location.title')"
      closable
      data-test-id="edit_drop_off-modal"
      @close="() => $emit('on:close', { isEdited: false })"
    >
      <div slot="body">
        <p class="emobg-font-small my-3">
          {{ $t('modal.edit_dropoff_location.subtitle') }}
        </p>
        <OneWaySelectorComponent
          :items="filteredOneWayLocations"
          :value="currentLocationUuid"
          :checked="isOneWay"
          :loading="isWaitingApiCall"
          :is-carpooling="booking.is_carpooling"
          data-test-id="return_location-selector"
          @change="currentState => isOneWay = currentState"
          @location="newLocation => destinationLocation = newLocation"
        />
        <p class="mt-3 mb-4 emobg-font-small emobg-color-ink-light">
          {{ $t('modal.edit_dropoff_location.remind_text') }}
        </p>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY})"
          class="mr-2"
          data-test-id="cancel-button"
          @clickbutton="$emit('on:close', { isEditing: false })"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>
        <ui-button
          v-bind="fetchButtonSpecs()"
          class="mr-2"
          data-test-id="submit-button"
          :disabled="isSubmitButtonDisabled"
          :loading="isLoading.editReturnLocation"
          @clickbutton="confirmEditDropOff"
        >
          {{ $t('buttons.confirm') }}
        </ui-button>
      </div>
    </ui-modal>
    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import reject from 'lodash/reject';
import find from 'lodash/find';
import delay from 'lodash/delay';
import isNull from 'lodash/isNull';
import map from 'lodash/map';

import { DATE_FORMAT, DELAY } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import OneWaySelectorComponent from '@/components/OneWaySelector/OneWaySelectorComponent';
import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import {
  fetchOperatorLocations,
  oneWayLocations,
} from '@/stores/OperatorLocations/OperatorLocationsMapper';

import Api from '@/vue/api/backoffice';

import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { composeReturnLocationUpdateEvent } from '@/components/MyBookings/modals/helpers/segment';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { SEGMENT_EVENTS } from '@/constants/segment';
import { useSegment } from '@/composable/Segment/segment';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'EditDropOffBookingModal',
  components: {
    FeedbackModalComponent,
    OneWaySelectorComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    const { trackSegment: trackSegmentEvent } = useSegment();

    return {
      fetchButtonSpecs,
      trackSegmentEvent,
    };
  },

  data() {
    return {
      destinationLocation: null,
      isOneWay: false,
      modals: {
        active: {
          isOpen: false,
        },

      },
      isLoading: {
        oneWayLocations: false,
        editReturnLocation: false,
      },
    };
  },

  computed: {
    oneWayLocations,

    ...mapGetters(UserDataNameSpace, {
      userData: 'getUserData',
    }),

    filteredOneWayLocations() {
      return map(this.oneWayLocations, ({ uuid, name }) => ({ value: uuid, label: name }));
    },

    bookingReturnUuid() {
      return get(this.booking, 'destination_location.uuid');
    },

    bookingPickUpUuid() {
      return get(this.booking, 'location.original_uuid');
    },

    currentLocationUuid() {
      return get(this, 'destinationLocation.uuid') || this.bookingPickUpUuid;
    },

    isBookingRequest() {
      return get(this, 'booking.isBookingRequest');
    },

    isWaitingApiCall() {
      return this.isLoading.oneWayLocations || this.isLoading.editReturnLocation;
    },

    isSameDestination() {
      return get(this, 'destinationLocation.uuid') === this.bookingReturnUuid;
    },

    isDestinationUnchanged() {
      return this.isSameDestination || (this.isOneWay && isNull(this.destinationLocation));
    },

    isRoundTripSaved() {
      return this.bookingPickUpUuid === this.bookingReturnUuid;
    },

    isSubmitButtonDisabled() {
      return this.isWaitingApiCall || this.isDestinationUnchanged || (this.isRoundTripSaved && !this.isOneWay);
    },

  },
  watch: {
    booking() {
      this.getOneWayLocations();
    },
  },
  created() {
    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.edit_dropoff_location.success_title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.$emit('on:close', { isEditing: true });
      },
    };
    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.cancel_booking.error.title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
      },
      primaryCallToActionText: this.$t('modal.cancel_booking.error.try_again'),
    };
    this.resetLocationData();
  },
  methods: {
    get,
    reject,
    find,
    delay,
    isNull,
    fetchOperatorLocations,

    resetLocationData() {
      this.isOneWay = !this.isRoundTripSaved;
      this.getOneWayLocations();
    },

    async confirmEditDropOff() {
      this.isLoading.editReturnLocation = true;
      let errorMessage;

      try {
        if (this.isBookingRequest) {
          const payload = {
            user_uuid: this.userData.uuid,
            return_location_uuid: this.currentLocationUuid,
          };
          await external.preBooking.patchPreBooking(this.booking.uuid, payload);
          await this.delayPreBookingEdition;
        } else {
          const payload = {
            user_uuid: this.userData.uuid,
            destination_location_uuid: this.currentLocationUuid,
            start: get(this.booking, 'start'),
            end: get(this.booking, 'end'),
            source: 'webapp',
          };
          await Api.carsharing.updateBooking.updateBooking(this.booking.uuid, payload);
        }
        this.modals.active = { ...this.modals.active, ...this.successModalArgs };
        this.modals.active.isOpen = true;
      } catch (error) {
        errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);

        this.modals.active = { ...this.modals.active, ...this.errorModalArgs, description: errorMessage };
        this.modals.active.isOpen = true;
      } finally {
        this.trackSegmentEvent({
          name: SEGMENT_EVENTS.returnLocationUpdated,
          data: composeReturnLocationUpdateEvent({
            booking: this.booking,
            newReturnLocation: get(this, 'destinationLocation.uuid'),
            errorMessage,
          }),
        });
        this.isLoading.editReturnLocation = false;
      }
    },

    async getOneWayLocations() {
      this.destinationLocation = null;
      this.isLoading.oneWayLocations = true;

      const bookingEnd = moment(get(this.booking, 'end', null));
      const query = bookingEnd.isValid() ? { booking_date: bookingEnd.format(DATE_FORMAT.filter) } : null;
      const operatorUuid = get(this.booking, 'csOperator.uuid');

      await this.fetchOperatorLocations({
        operatorUuid,
        query,
      });
      this.destinationLocation = this.isOneWay
        ? find(this.oneWayLocations, { uuid: this.bookingReturnUuid })
        : null;
      this.isLoading.oneWayLocations = false;
    },

    delayPreBookingEdition(preBookingResponse) {
      // Backend does something asynchronous and we have to wait until
      // it will be finished (more or less)
      const delayUnit = DELAY.extraLong + DELAY.medium;

      return new Promise(resolve => {
        delay(() => {
          resolve(preBookingResponse);
        }, delayUnit);
      });
    },
  },
};
</script>
