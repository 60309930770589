<script>
import {
  DELAY, filterArray, isArrayEmpty, isStringValue, toLowercaseString,
} from '@emobg/web-utils';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import { employeeList, getEmployeeList } from '@/stores/Company/CompanyMapper';

import ALERT_TYPES from '@/components/Alert/alertTypes';
import AlertComponent from '@/components/Alert/AlertComponent';

export default {
  name: 'AdditionalDriversComponent',

  components: {
    AlertComponent,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasInvalidDrivers: {
      type: Boolean,
      default: false,
    },
    selectedDrivers: {
      type: Array,
      default: () => [],
    },
    bookingOwnerUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSelectOpen: false,
      selectEmployeeOptions: [],
      currentDrivers: [],
      tempDrivers: [],
    };
  },
  computed: {
    employeeList,
    displayedDrivers() {
      const employees = filterArray(
        this.employeeList,
        (employee) => (!this.selectedDrivers.includes(employee.email) && employee.userUuid !== this.bookingOwnerUuid),
      );
      return employees || [];
    },
    selectValue() {
      return isArrayEmpty(this.currentDrivers) ? '' : this.currentDrivers.join(', ');
    },
  },
  watch: {
    selectedDrivers(newValue) {
      this.$refs.additionalDriverSelector.getElementsByTagName('INPUT')[1].value = newValue.join(', ');
    },
  },

  async created() {
    this.ALERT_TYPES = ALERT_TYPES;
    await this.getEmployeeList();
    this.selectEmployeeOptions = this.displayedDrivers;
    this.currentDrivers = this.selectedDrivers;
  },
  methods: {
    getEmployeeList,
    onSelectOption(email) {
      const emailSanitized = trim(email);
      if (!isEmpty(emailSanitized)) {
        this.$emit('add-driver', emailSanitized);
      }
      this.currentDrivers = this.tempDrivers;
      this.isSelectOpen = false;
      setTimeout(() => {
        this.$refs.additionalDriverSelector.getElementsByTagName('INPUT')[1].value = this.selectedDrivers.join(', ');
      }, DELAY.tiny);
    },
    employeeFilter({ detail }) {
      this.isSelectOpen = true;
      const filterableProperties = ['email'];
      this.selectEmployeeOptions = filterArray(this.displayedDrivers, (employee) => {
        let isInSearch = false;
        filterableProperties.forEach(key => {
          if (!isInSearch) {
            isInSearch = isStringValue(employee[key])
              && toLowercaseString(employee[key]).includes(toLowercaseString(detail));
          }
        });
        return isInSearch;
      });
    },
    resetValue() {
      this.tempDrivers = this.currentDrivers;
      this.currentDrivers = [];
      this.$refs.additionalDriverSelector.getElementsByTagName('INPUT')[1].value = '';
      this.selectEmployeeOptions = filterArray(this.selectEmployeeOptions, employee => !this.selectedDrivers.includes(employee.email));
    },
  },
};
</script>

<template>
  <div
    class="AdditionalDriversComponent"
    data-test-id="additional_driver-component"
  >
    <div class="pb-3">
      {{ $t('carsharing_personal.additional_drivers.select_label') }}
    </div>
    <div class="mb-2">
      <ui-select
        v-if="displayedDrivers"
        ref="additionalDriverSelector"
        :label="$t('carsharing_personal.additional_drivers.select_info')"
        :placeholder="$t('carsharing_personal.additional_drivers.select_placeholder')"
        :open="isSelectOpen"
        :disable="disabled"
        :value="selectValue"
        filter
        @changefilter="employeeFilter"
        @click="resetValue"
      >
        <div slot="content">
          <div
            v-for="(employee, index) in selectEmployeeOptions"
            :key="index"
            :class="`p-1 cursor-pointer emobg-background-color-ground-lighter-hover ${hasInvalidDrivers ? 'pb-0' : 'pb-2'}`"
            @click="onSelectOption(employee.email)"
          >
            {{ employee.email }}
          </div>
        </div>
      </ui-select>
      <ui-skeleton v-else />
    </div>
    <div
      v-if="hasInvalidDrivers"
      data-test-id="error-message"
      class="input-error-message emobg-font-x-small mb-3"
    >
      {{ $t('carsharing_personal.additional_drivers.invalid_driver_email') }}
    </div>
    <AlertComponent
      :type="ALERT_TYPES.warning"
      data-test-id="alert-additional-drivers"
    >
      {{ $t('carsharing_personal.additional_drivers.info_text') }}
    </AlertComponent>
  </div>
</template>
