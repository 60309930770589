import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import { LOG_TYPE, logger } from '@emobg/web-utils';

import { BOOKING_TRIP_TYPES } from '@/constants/bookingTripTypes';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { getBookingUuid, getFleetType } from '@/helpers/booking/bookingHelpers';

/**
 * Return object with formatted data to send to Segment
 * @param {object} booking
 * @param {string} newReturnLocation
 * @param {string} errorMessage
 * @return {object}
 */
export const composeReturnLocationUpdateEvent = ({ booking, newReturnLocation, errorMessage }) => {
  if (isEmpty(booking) || !isPlainObject(booking)) {
    const logErrorMessage = 'booking argument must be provided and be an object';
    logger.message(logErrorMessage, LOG_TYPE.error);
    throw new Error(logErrorMessage);
  }

  const originalLocation = get(booking, 'location.original_uuid');
  const destinationLocation = get(booking, 'destination_location.uuid');
  return {
    bookingType: get(booking, 'cs_booking_use_case.booking_type'),
    bookingId: get(booking, 'uuid'),
    originalReturnLocationId: get(booking, 'destination_location.uuid'),
    originalBookingTripType: isEmpty(destinationLocation) || (originalLocation === destinationLocation)
      ? BOOKING_TRIP_TYPES.roundTrip
      : BOOKING_TRIP_TYPES.oneWay,
    vehicleCategoryType: get(booking, 'vehicle.category'),
    newReturnLocationId: newReturnLocation,
    newBookingTripType: isEmpty(newReturnLocation) || (originalLocation === newReturnLocation)
      ? BOOKING_TRIP_TYPES.roundTrip
      : BOOKING_TRIP_TYPES.oneWay,
    unsuccessfullReason: errorMessage,
  };
};

/**
 * Return object with formatted data to send to Segment for Cancel Booking
 * @param {object} booking
 * @param {string} errorMessage
 * @return {object}
 */
export const composeCancelBookingEventData = ({ booking, errorMessage = '' }) => {
  if (isEmpty(booking) || !isPlainObject(booking)) {
    const logErrorMessage = 'booking argument must be provided and be an object';
    logger.message(logErrorMessage, LOG_TYPE.error);
    throw new Error(logErrorMessage);
  }

  return {
    bookingUuid: getBookingUuid({ booking }),
    bookingType: get(booking, 'cs_booking_use_case.booking_type', BOOKING_TYPES.carsharing),
    fleetType: getFleetType({ booking }),
    usageStatus: get(booking, 'usage_status_key'),
    errorMessage,
  };
};
