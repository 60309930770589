<script>
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'AdditionalPassengersComponent',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      passenger: null,
    };
  },

  methods: {
    onAddItem() {
      const passenger = trim(this.passenger);

      if (isEmpty(passenger)) {
        return;
      }
      this.passenger = '';
      this.$emit('add-passenger', passenger);
    },
  },
};
</script>

<template>
  <div
    data-test-id="additional_passenger-component"
    class="d-flex align-items-end"
  >
    <ui-text-input
      :value="passenger"
      :placeholder="$t('carsharing_personal.additional_passengers_input_placeholder')"
      :label="$t('carsharing_personal.additional_passengers_label')"
      :disabled="disabled"
      data-test-id="input"
      name="additionalPassengers"
      class="w-90"
      @changevalue="({ detail }) => passenger = detail"
      @keydown.enter.prevent="onAddItem"
    />
    <ui-button
      v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
      :disabled="disabled"
      data-test-id="button"
      class="ml-3"
      @clickbutton="onAddItem"
    >
      {{ $t('carsharing_personal.additional_passengers_button_text') }}
    </ui-button>
  </div>
</template>
