<script>
import size from 'lodash/size';
import get from 'lodash/get';
import every from 'lodash/every';
import has from 'lodash/has';

import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';

export default {
  name: 'OneWaySelectorComponent',

  components: {
    AlertComponent,
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      validation: items => every(items, item => has(item, 'value') && has(item, 'label')),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCarpooling: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOneWay: this.checked,
    };
  },

  computed: {
    selectPlaceholder() {
      return size(this.items)
        ? this.$t('Carsharing.views.BookingSummary.OneWaySelector.select_placeholder')
        : this.$t('Carsharing.views.BookingSummary.OneWaySelector.select_placeholder_empty_state');
    },
  },

  created() {
    this.ALERT_TYPES = ALERT_TYPES;
  },

  methods: {
    onRadioTriggered({ isOneWay }) {
      this.isOneWay = isOneWay;

      if (!isOneWay) {
        this.onChangeItem(null);
      }
      this.$emit('change', isOneWay);
    },
    onChangeItem(item) {
      const newSelectedLocation = get(item, 'detail');
      this.$emit('location', item ? { uuid: newSelectedLocation } : null);
    },
  },
};
</script>

<template>
  <div
    class="row no-gutters flex-grow-1"
    data-test-id="one_way_selector-component"
  >
    <ui-radio
      :value="isOneWay"
      :option="false"
      :caption="$t('modal.edit_dropoff_location.round_trip')"
      :disabled="isDisabled"
      name="destination_location"
      data-test-id="round_trip-radio_button"
      class="mr-3"
      @changevalue="() => onRadioTriggered({ isOneWay: false })"
    />
    <ui-radio
      :value="isOneWay"
      :option="true"
      :caption="$t('modal.edit_dropoff_location.one_way')"
      :disabled="isDisabled"
      name="destination_location"
      data-test-id="one_way-radio_button"
      class="mr-3"
      @changevalue="() => onRadioTriggered({ isOneWay: true })"
    />

    <AlertComponent
      v-if="isOneWay && isCarpooling"
      :type="ALERT_TYPES.warning"
      data-test-id="car_pooling-warning"
      class="w-100"
    >
      {{ $t('modal.edit_dropoff_location.carpooling_warning') }}
    </AlertComponent>

    <div
      v-if="isOneWay"
      class="col-12 mb-1 emobg-font-line-height-default mt-3 order-last"
    >
      <ui-loader
        v-if="loading"
        data-test-id="loader"
      />
      <ui-select
        v-else
        ref="select"
        :value="value"
        :options.prop="items"
        :label="$t('Carsharing.views.BookingSummary.OneWaySelector.select_label')"
        :disabled="isDisabled || !isOneWay"
        :placeholder="selectPlaceholder"
        name="oneWaySelector"
        data-test-id="location-select"
        class="w-100 text-wrap"
        @selectoption="onChangeItem"
      />
    </div>
  </div>
</template>
