<template>
  <ui-modal
    :open="isOpen"
    :size="SIZES.small"
    :header="$t('modal.cancel_booking.title')"
    closable
    data-test-id="cancel-booking-modal"
    @close="() => closeModal(false)"
  >
    <div slot="body">
      <div>
        <div
          v-if="!isLoading.bookingCancellationFee && bookingCancellationFee"
          data-test-id="cancelation-info"
        >
          <p data-test-id="info-text">
            {{ bookingCancellationFee.text }}
          </p>

          <div
            v-if="!bookingCancellationFee.is_unpriced_tariff"
            data-test-id="fee-info"
            class="mt-4"
          >
            <span class="emobg-font-small">
              {{ $t('modal.cancel_booking.cancel_title') }}
            </span>
            <table class="table tableFees mt-2">
              <tbody>
                <tr>
                  <th
                    colspan="2"
                    data-test-id="charge"
                    class="emobg-font-default"
                  >
                    {{ bookingCancellationFee.charge }}
                  </th>
                </tr>
                <tr class="emobg-font-weight-bold emobg-font-medium">
                  <th>{{ $t('modal.cancel_booking.total') }}</th>
                  <td
                    class="text-right"
                    data-test-id="total-charge"
                  >
                    {{ bookingCancellationFee.total }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <p class="mt-4">
            {{ $t('modal.cancel_booking.confirmation_subtitle') }}
          </p>
        </div>
        <ui-skeleton
          v-if="isLoading.bookingCancellationFee"
          height="157"
          class="d-block my-3"
        />
      </div>
      <FeedbackModalComponent
        v-model="modals.active.isOpen"
        v-bind="modals.active"
      />
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-for="(button, index) in getModalButtons"
        :key="`button-${index}`"
        :class="[
          'my-1',
          {
            'ml-3': index
          }
        ]"
        v-bind="button.attributes"
        v-on="button.listeners"
      >
        {{ button.text }}
      </ui-button>
    </div>
  </ui-modal>
</template>
<script>
import get from 'lodash/get';
import { deletePreBooking, getBookingCancelFees, putCancelBooking } from '@/stores/Booking/BookingMapper';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import { useSegment } from '@/composable/Segment/segment';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { composeCancelBookingEventData } from '@/components/MyBookings/modals/helpers/segment';
import { userUuid } from '@/stores/User/UserData/UserDataMapper';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';

import { bookingCancelled } from '@/utils/publicImages';
import { SEGMENT_EVENTS } from '@/constants/segment';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'CancelBookingModal',
  components: {
    FeedbackModalComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    const { trackSegment } = useSegment();

    return { fetchButtonSpecs, trackSegment };
  },

  data() {
    return {
      bookingCancellationFee: undefined,
      modals: {
        active: {
          isOpen: false,
        },
      },
      isLoading: {
        bookingCancellationFee: false,
        cancelBooking: false,
      },
    };
  },
  computed: {
    userUuid,

    isWaitingApiCall() {
      return this.isLoading.bookingCancellationFee;
    },

    isBookingRequest() {
      return get(this, 'booking.isBookingRequest');
    },
    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            'data-test-id': 'close-modal',
            disabled: this.isLoading.cancelBooking,
          },
          listeners: {
            clickbutton: () => this.closeModal(false),
          },
          text: this.$t('buttons.no_go_back'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.DESTRUCTIVE }),
            disabled: this.isWaitingApiCall,
            loading: this.isLoading.cancelBooking,
            class: 'mr-2',
            'data-test-id': 'submit-cancel-booking',
          },
          listeners: {
            clickbutton: this.confirmCancelBooking,
          },
          text: this.$t('buttons.confirm_cancel_action'),
        },
      ];
    },
  },
  watch: {
    async booking() {
      this.bookingCancellationFee = undefined;
      if (!this.isBookingRequest) {
        await this.fetchCancellationFees();
      }
    },
  },
  async created() {
    if (!this.isBookingRequest) {
      await this.fetchCancellationFees();
    }
    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.cancel_booking.error.title'),
      primaryCallToActionText: this.$t('modal.cancel_booking.error.try_again'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.closeModal(true);
      },
    };
    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.cancel_booking.success.title'),
      image: bookingCancelled,
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.closeModal(true);
      },
    };
  },
  methods: {
    deletePreBooking,
    getBookingCancelFees,
    putCancelBooking,

    closeModal(isCanceling) {
      this.$emit('on:close', isCanceling);
    },

    async confirmCancelBooking() {
      this.isLoading.cancelBooking = true;

      const cancelApiCall = this.isBookingRequest
        ? this.deletePreBooking({ preBookingUuid: get(this, 'booking.uuid') })
        : this.putCancelBooking({
          bookingUuid: get(this, 'booking.uuid'),
          data: {
            user_uuid: get(this, 'userUuid'),
          },
        });

      try {
        await Promise.resolve(cancelApiCall);
        this.modals.active = { ...this.modals.active, ...this.successModalArgs };
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.modals.active = { ...this.modals.active, ...this.errorModalArgs, description: this.errorMessage };
      } finally {
        this.trackSegment({
          name: SEGMENT_EVENTS.cancelBooking,
          data: composeCancelBookingEventData({
            booking: this.booking,
            errorMessage: this.errorMessage,
          }),
        });
      }

      this.modals.active.isOpen = true;
      this.isLoading.cancelBooking = false;
    },

    async fetchCancellationFees() {
      this.isLoading.bookingCancellationFee = true;
      this.bookingCancellationFee = await this.getBookingCancelFees({ bookingUuid: this.booking.uuid });
      this.isLoading.bookingCancellationFee = false;
    },
  },
};
</script>
