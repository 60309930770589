<script>
export default {
  name: 'RemovableBadgeComponent',

  props: {
    onRemove: {
      type: Function,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },

};
</script>

<template>
  <ui-badge
    v-bind="$attrs"
    :data-test-id="`${$attrs.dataTestId}-badge`"
    class="RemovableBadge d-inline-flex align-content-center"
  >
    <slot />
    <ui-icon
      v-if="editable"
      :size="ICONS_SIZES.xSmall"
      :icon="ICONS.removeFilled"
      :color="GRAYSCALE.inkLight"
      :data-test-id="`${$attrs.dataTestId}-remove_button`"
      class="cursor-pointer d-inline-flex m-1 mr-n1"
      @click="() => onRemove()"
    />
  </ui-badge>
</template>

<style lang="scss">
.RemovableBadge {
  .Ui-Badge {
    &__body {
      display: inline-flex;
      line-height: 22px !important;
    }
  }
}
</style>
