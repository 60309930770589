import { readonly, ref } from 'vue';

/**
 * Properties to be applied to a booking modal
 * @typedef {Object} BookingModal
 * @property {boolean} isModalOpen - Controls if modal is open
 * @property {Object} bookingDataModal - The booking data to be passed as a prop to the modal
 * @property {Function} openModal - Function to call to open the modal
 * @property {Function} closeModal - Function to call to close the modal
 */

/**
 * @typedef {Object} OpenModalParams
 * @property {*} booking - unsanitized booking data to be transformed by `transformer` function
 * @property {Function} transformer - optional transformer function that will sanitize booking data
 */

/**
 * Initializes properties to be applied to a booking modal
 * @returns {BookingModal}
 */
export const useBookingModal = () => {
  const isModalOpen = ref(false);
  const bookingDataModal = ref({});

  /**
   * Default transformer that returns `context` unchanged as a booking data
   * @param {*} context - context argument to be treated as a booking data
   * @returns {Object} booking data unchanged from `context`
   */
  const defaultTransformer = context => context;

  /**
   * Function to call to open the modal and assign the booking data
   * @param {OpenModalParams}
   */
  const openModal = ({ booking, transformer = defaultTransformer }) => {
    isModalOpen.value = true;
    bookingDataModal.value = transformer(booking);
  };

  /**
   * Function to call to close the modal and clean booking data
   */
  const closeModal = () => {
    isModalOpen.value = false;
    bookingDataModal.value = null;
  };

  return {
    isModalOpen: readonly(isModalOpen),
    bookingDataModal: readonly(bookingDataModal),
    openModal,
    closeModal,
  };
};
